* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Helvetica", "Arial", sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.app {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: top left;
}

.video-placeholder {
  z-index: -1;
  position: fixed;
  opacity: 0;
}

.marker {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: rgba(120, 220, 120, 0.3);
  transition: transform 50ms ease-out, opacity 50ms ease-out;
}

.marker[data-active="true"] {
  opacity: 1;
}

.marker[data-content]::after {
  content: attr(data-content);
  position: absolute;
  right: -0.25rem;
  top: 50%;
  transform: translate(100%, -50%);
  font-size: 1rem;
  color: rgb(120, 220, 120);
  white-space: nowrap;
}

.line {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 50ms ease-out, opacity 50ms ease-out;
}

.line[data-line-orientation="top-left-to-bottom-right"] {
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 5px),
    rgba(120, 220, 120, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 5px),
    rgba(0, 0, 0, 0) 100%
  );
}

.line[data-line-orientation="bottom-left-to-top-right"] {
  background: linear-gradient(
    to top right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 5px),
    rgba(120, 220, 120, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 5px),
    rgba(0, 0, 0, 0) 100%
  );
}

.line[data-active="true"] {
  opacity: 1;
}

.detections {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: grid;
  grid-template-columns: 1fr;
  max-height: 20vh;
  margin: 0;
  overflow-y: auto;
  gap: 1rem 2rem;
  padding: 1rem;
  transform: translateX(-50%);
  border-radius: 1rem;
  font-size: 0.8rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1rem);
}

@media (min-width: 40rem) {
  .detections {
    bottom: 2rem;
    grid-template-columns: 1fr 1fr;
    max-height: unset;
    padding: 2rem;
    overflow-y: visible;
    font-size: 1rem;
  }
}

@media (min-width: 60rem) {
  .detections {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.detections__item {
  display: grid;
  grid-template-columns: 5rem 1fr;
}

@media (min-width: 60rem) {
  .detections__item--wide {
    grid-column: 1/-1;
  }
}

.detections__title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
}

.detections__title[data-side]::after {
  content: attr(data-side);
  position: absolute;
  left: calc(50% + 1rem);
  top: 50%;
  transform: translate(100%, -50%);
  text-transform: uppercase;
}

.detections__data {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-height: 1rem;
  white-space: nowrap;
}

.detections__data span[data-score="good"] {
  color: rgb(120, 220, 120);
}

.detections__data span[data-score="bad"] {
  color: rgb(220, 120, 120);
}

.detections__icon {
  width: 1.2rem;
  height: 1.2rem;
  object-fit: contain;
}

@media (min-width: 60rem) {
  .detections__icon {
    width: 2rem;
    height: 2rem;
  }
}

.squad-counter {
  z-index: 1000;
  position: fixed;
  top: 2rem;
  right: 2rem;
  font-size: 10vh;
  padding: 1rem 2rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1rem);
}

.webcam-selector {
  position: absolute;
  top: 3vh;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 80rem) {
  .webcam-selector {
    left: 25%;
  }
}

.webcam-selector__select {
  padding: 1rem;
  border-radius: 1rem;
  border-color: transparent;
  border-right: 1rem solid transparent;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(1rem);
}
