* {
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  overflow: hidden;
}

.app {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: top left;
}

.video-placeholder {
  z-index: -1;
  opacity: 0;
  position: fixed;
}

.marker {
  opacity: 0;
  width: 2rem;
  height: 2rem;
  background-color: #78dc784d;
  border-radius: 100%;
  transition: transform 50ms ease-out, opacity 50ms ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.marker[data-active="true"] {
  opacity: 1;
}

.marker[data-content]:after {
  content: attr(data-content);
  color: #78dc78;
  white-space: nowrap;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  right: -.25rem;
  transform: translate(100%, -50%);
}

.line {
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: transform 50ms ease-out, opacity 50ms ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.line[data-line-orientation="top-left-to-bottom-right"] {
  background: linear-gradient(to top left, #0000 0% calc(50% - 5px), #78dc78 50%, #0000 calc(50% + 5px) 100%);
}

.line[data-line-orientation="bottom-left-to-top-right"] {
  background: linear-gradient(to top right, #0000 0% calc(50% - 5px), #78dc78 50%, #0000 calc(50% + 5px) 100%);
}

.line[data-active="true"] {
  opacity: 1;
}

.detections {
  max-height: 20vh;
  color: #fff;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  background-color: #0003;
  border-radius: 1rem;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;
  margin: 0;
  padding: 1rem;
  font-size: .8rem;
  display: grid;
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow-y: auto;
  transform: translateX(-50%);
}

@media (min-width: 40rem) {
  .detections {
    max-height: unset;
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
    font-size: 1rem;
    bottom: 2rem;
    overflow-y: visible;
  }
}

@media (min-width: 60rem) {
  .detections {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.detections__item {
  grid-template-columns: 5rem 1fr;
  display: grid;
}

@media (min-width: 60rem) {
  .detections__item--wide {
    grid-column: 1 / -1;
  }
}

.detections__title {
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-weight: bold;
  display: flex;
  position: relative;
}

.detections__title[data-side]:after {
  content: attr(data-side);
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: calc(50% + 1rem);
  transform: translate(100%, -50%);
}

.detections__data {
  min-height: 1rem;
  white-space: nowrap;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.detections__data span[data-score="good"] {
  color: #78dc78;
}

.detections__data span[data-score="bad"] {
  color: #dc7878;
}

.detections__icon {
  width: 1.2rem;
  height: 1.2rem;
  object-fit: contain;
}

@media (min-width: 60rem) {
  .detections__icon {
    width: 2rem;
    height: 2rem;
  }
}

.squad-counter {
  z-index: 1000;
  color: #fff;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  background-color: #0003;
  padding: 1rem 2rem;
  font-size: 10vh;
  position: fixed;
  top: 2rem;
  right: 2rem;
}

.webcam-selector {
  position: absolute;
  top: 3vh;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 80rem) {
  .webcam-selector {
    left: 25%;
  }
}

.webcam-selector__select {
  color: #fff;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  background-color: #0006;
  border-color: #0000;
  border-right-style: solid;
  border-right-width: 1rem;
  border-radius: 1rem;
  padding: 1rem;
}

/*# sourceMappingURL=index.6f48a98a.css.map */
